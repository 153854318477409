/*
* Modal
*/

.overlay {
    .modal_event {
        width: 130vh;
        height: 80vh;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        background: url("../../img/section4/BG_PopUp.png") center no-repeat;
        background-size: contain;
        padding: 2vw;
        box-shadow: none;

        > .content {
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            > p {
                font-size: 1.2rem;

                @media screen and (max-width: 820px) and (orientation: landscape) {
                    font-size: .9rem;
                }
    
                @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                    font-size: 1rem;
                }
            }

            > .title {
                position: absolute;
                left: 5vw;
                top: 4.5vw;
                font-size: 1.2vw;
            }
            
            > .btn-close {
                padding: 1.2% 15%;
                color: #262828;
                background-color: #ffe500;
                font-family: "RefrigeratorDeluxeBold";
                font-size: 1.5rem;

                @media screen and (max-width: 820px) and (orientation: landscape) {
                    font-size: 1rem;
                }
    
                @media screen and (min-width: 820px) and (max-width: 980px) and (orientation: landscape) {
                    font-size: 1.2rem;
                }

                &:hover {
                    background-color: #b09f00;
                    cursor: pointer;
                }
            }
        }
    }
}